@import './addresssearch.module.css';
@import './header.module.css';
@import './hero.module.css';
@import './Home.module.css';
@import './search-bar.module.css';
@import './carousel.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: 'GT America';
		src: local('GT America'),
			url(../public/fonts/GTAmerica/GT\ America\ Regular.ttf) format('truetype');
		font-weight: 300;
	}

	@font-face {
		font-family: 'GT America';
		src: local('GT America'),
			url(../public/fonts/GTAmerica/GT\ America\ Bold.ttf) format('truetype');
		font-weight: 700;
	}

	@font-face {
		font-family: 'Source Serif Pro';
		src: local('Source Serif Pro'),
			url(../public/fonts/SourceSerifPro/SourceSerifPro-Regular.ttf)
				format('truetype');
		font-weight: 300;
	}

	@font-face {
		font-family: 'Source Serif Pro';
		src: local('Source Serif Pro'),
			url(../public/fonts/SourceSerifPro/SourceSerifPro-Bold.ttf)
				format('truetype');
		font-weight: 700;
	}

	@font-face {
		font-family: 'Source Serif Pro';
		src: local('Source Serif Pro'),
			url(../public/fonts/SourceSerifPro/SourceSerifPro-SemiBold.ttf)
				format('truetype');
		font-weight: 600;
	}

	@font-face {
		font-family: 'Source Serif Pro';
		src: local('Source Serif Pro'),
			url(../public/fonts/SourceSerifPro/SourceSerifPro-Black.ttf)
				format('truetype');
		font-weight: 900;
	}
}

@layer utilities {
	.nav-link-light {
		@apply text-white flex items-center border-b-2 border-transparent focus:outline-none hover:border-gray-300 focus:border-gray-300 py-1 focus:outline-none focus:ring;
	}

	.nav-link-dark {
		@apply text-brand-charcoal-dark flex items-center border-b-2 border-transparent focus:outline-none hover:border-gray-300 focus:border-gray-300 py-1 focus:outline-none focus:ring;
	}

	.nav-link-active {
		@apply font-medium border-brand-blue hover:border-brand-blue focus:border-brand-blue;
	}
}

.start-main {
	grid-column-start: main;
}

.col-span-main {
	grid-column: main;
}

.container-grid {
	grid-template-columns:
		[full-start] minmax(1.5em, 1fr) [main-start] minmax(0, 72em)
		[main-end] minmax(1.5em, 1fr) [full-end];
}

.container-grid-center {
	display: flex;
	justify-content: center;
}

.col-span-container-grid {
	grid-column: full;
}

.location-map {
	position: relative;
	grid-column: full;
}

@screen lg {
	.location-map {
		@apply absolute left-0 top-0 h-full w-1/2;
	}
}

.location-list {
	line-height: 300%;
}

.location-footer a {
	@apply font-bold underline;
}

.review-listing-hero-image {
	position: relative;
	grid-column: full;
	max-height: 400px;
	overflow: hidden;

	> div {
		height: 100%;
	}
}

@screen lg {
	.review-listing-hero-image {
		@apply absolute left-0 top-0 h-full w-1/4;
		max-height: 100%;
		overflow: visible;

		> div {
			height: 100%;
		}
	}
}

.review-listing-scrim {
	background: linear-gradient(
		to left,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0.55)
	);
}

.grabbable {
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

.grabbable:active {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.location-photo-card:hover,
.location-photo-card:focus {
	.location-photo-card-enlarge {
		opacity: 1;
	}
}

.location-photo-card-enlarge {
	opacity: 0;
}

.location-photos-dialog-backdrop {
	@apply fixed inset-0 transition-opacity duration-200 bg-white bg-opacity-90 w-full h-full z-10;
	perspective: 800px;
	opacity: 0;

	&[data-enter] {
		opacity: 1;
	}
}

.location-photos-dialog {
	@apply transition-opacity duration-200 w-full h-full flex items-center justify-center;
	opacity: 0;

	&[data-enter] {
		opacity: 1;
	}
}

.with-lazy-bg {
	overflow: hidden;
	div:before {
		@apply absolute inset-0 pointer-events-none flex items-center justify-center;
		content: '';
		background-image: url('/logo-glyph.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 50px;
		opacity: 10%;
	}
}

.table-scrim {
	background: linear-gradient(
		to right,
		rgba(254, 248, 246, 0.35),
		rgba(254, 248, 246, 1)
	);
}

.map-popup {
	&.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
		border-top-color: theme('colors.brand.blue') !important;
	}

	&.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
		border-top-color: theme('colors.brand.blue') !important;
	}

	&.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
		border-top-color: theme('colors.brand.blue') !important;
	}

	.mapboxgl-popup-content {
		@apply bg-brand-blue text-sm text-white;
	}
}

.transform-hack {
	transform: translateZ(0);
}

.location-photo span {
	height: 100%;
	display: block;
}

.page-header-block {
	a {
		@apply text-brand-blue hover:underline;
	}
}

.tooltip-nav li:first-of-type a {
	@apply rounded-tl-lg rounded-tr-lg;
}

.tooltip-nav li:last-of-type a {
	@apply rounded-bl-lg rounded-br-lg;
}

.floating-input.has-error label {
	@apply text-red-600 !important;
}

.datepicker-input.has-error input {
	@apply text-red-600;
}

.featured-article-box {
	@apply relative;
}

.featured-article-box:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	border-width: 0 32px 32px 0;
	border-style: solid;
	border-color: #ededed #fff;
}

.carousel-cell-image {
	transition: opacity 0.4s;
	opacity: 0;
}

.careers-callout-block a {
	@apply hover:underline;
}

.landlord-hero-image {
	position: relative;
	grid-column: full;
}

@screen lg {
	.landlord-hero-image {
		@apply absolute left-0 top-0 h-full w-1/2;
	}
}

.landlord-hero-image {
	.swiper-slide {
		width: 75%;
	}
}

.partner-quote {
	@apply relative pt-20;
}

.partner-quote:before {
	@apply text-8xl text-brand-orange capsize absolute top-[16px] left-[-4px];
	content: '“';
}

.featured-location-slider {
	.swiper-slide {
		width: 75%;
	}
}

.prose img {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.DayPickerInput {
	width: 100%;

	input {
		@apply block p-3 w-full text-lg appearance-none focus:outline-none bg-transparent;
	}
}

.listing-slider {
	--swiper-navigation-size: 18px;
	--swiper-navigation-color: theme('colors.brand.blue');

	.swiper-pagination-bullet-active {
		@apply bg-brand-blue !important;
	}
}

.page-header-block {
	p:empty {
		margin: 16px 0;
	}
}

.checkbox:checked {
	line-height: 25px;
	text-align: center;
	background: transparent;
	background-color: #0c1e7d;
	outline: none;
	border: none;
	box-shadow: none !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url('/checkmark.svg');
	background-repeat: no-repeat;
	background-size: 50%;
	background-position-x: center;
	background-position-y: center;
}

.checkbox {
	border: solid 2px #bac2cc;
	border-radius: 4px;
	background-color: #ffffff;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.checkbox:checked:after {
	height: 20px;
	width: 20px;
	text-align: center;
	background: transparent;
	outline: none;
	box-shadow: none !important;
}

.swiper-button-next,
.swiper-button-prev {
	color: white !important;
	z-index: 40;
	display: block;
}

.swiper-button-next {
	background-image: url(/circleArrowNext.svg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	height: 60px !important;
	width: 60px !important;
}

.swiper-button-prev {
	background-image: url(/circleArrowPrev.svg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	height: 60px !important;
	width: 60px !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
	display: none;
}

.swiper-pagination {
	z-index: 40;
	display: block;
}

.swiper-pagination-bullet-active {
	background-color: #1642f0 !important;
}

.input-container > div {
	width: 100%;
}

.image-container > div {
	position: unset !important;
}

.image {
	object-fit: contain;
	width: 100% !important;
	position: relative !important;
	height: unset !important;
}

/* Styles applied because of react-draft-wysiwyg issue with custom fonts and headings */
/* https://github.com/jpuri/react-draft-wysiwyg/issues/1240 */
.DraftEditor-editorContainer .public-DraftEditor-content {
	font-family: 'GT America';
	font-size: medium;
	line-height: normal;
}

.DraftEditor-editorContainer .public-DraftEditor-content h3,
.DraftEditor-editorContainer .public-DraftEditor-content h4,
.DraftEditor-editorContainer .public-DraftEditor-content h5,
.DraftEditor-editorContainer .public-DraftEditor-content h6 {
	font-size: 16px !important;
	font-weight: bolder;
}
