.DayPicker {
	border: 1px solid #f5f3ee;
	padding: 20px;
	border-radius: 10px;
}

.DayPicker-Overlay {
	border: 1px solid #f5f3ee;
	border-radius: 10px;
	filter: drop-shadow(15px 15px 14px #f5f3ee);
}

.DayPicker-Day {
	font-family: 'GT America';
	color: #343333;
}

.DayPicker-Day {
	width: 100px !important;
}

.DayPicker-Day--today {
	font-weight: normal !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	background-color: #d9ddf0 !important;
	color: #343333 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #d9ddf0 !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
	.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background-color: #d9ddf0 !important;
}

div.DayPicker-Day.DayPicker-Day--disabled {
	background-color: unset !important;
	color: rgba(0, 0, 0, 0.38);
}

div.DayPicker-Day.DayPicker-Day--outside {
	background-color: unset !important;
}

div.DayPicker-Weekday {
	font-family: 'GT America';
	color: black;
}

.DayPicker-Caption > div {
	font-family: 'Source Serif Pro';
	font-weight: bold !important;
	font-size: 22px !important;
	text-align: center;
}

.DayPicker-Month {
	width: 290px;
}

.DayPicker-NavButton {
	color: #000000 !important;
	padding: 6px !important;
	width: 36px !important;
	height: 36px !important;
}

.DayPicker-NavButton--prev {
	margin-right: 250px !important;
	background-image: url('../public/left-chevron.png') !important;
}

.DayPicker-NavButton--next {
	background-image: url('../public/right-chevron.png') !important;
}

.DayPicker-NavButton:hover {
	opacity: unset !important;
}

.MoveInInput::placeholder {
	color: green !important;
	opacity: 1;
}

.MoveInInput {
	background-color: red;
}
