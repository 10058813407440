.search_icon_container {
	padding: 0 8px;
	position: absolute;
	margin-top: 12px;
}

.search_icon {
	height: 24px;
	width: 24px;
}

.address_search_input {
	padding: 12px 12px 12px 40px;
	border: 1px solid #0c1e7d;
	border-radius: 50px;
	height: 82px;
	font-size: 24px;
}

.address_search_input:focus {
	outline: none;
}
