.swiper-pagination-custom {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    padding: 0.25rem 1.5rem;
    background-color: rgba(255, 255, 255, .8);
    font-size: 16px;
    font-weight: 700;
    color: var(--tailwind-gray-600, #4B5563);
    font-family: 'GT America';
    border-radius: 99px;
    border: 1px solid var(--tailwind-gray-600, #4B5563);
}

.carousel-full .swiper-button-next, .carousel-full .swiper-button-prev {
    background-image: none;
    width: 20px !important;
}

@media screen and (min-width: 640px) {
    .carousel-full .swiper-button-next, .carousel-full .swiper-button-prev {
        width: 40px !important;
    }
}

.facility-card-carousel .swiper-button-disabled {
    display: none;
}