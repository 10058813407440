.header-container {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 40;
}

.header-sticky-container {
	width: 100%;
	z-index: 40;
}

.header-flex-container {
	height: 60px;
}

.header-img {
	flex-basis: 5%;
}

.header-nav-options {
	flex-basis: 61%;
}

.header-phone-nbr {
	flex-basis: 14%;
	font-size: 16px;
}

.header-log-in {
	flex-basis: 10%;
	font-size: 16px;
}

.header-initial-circle {
	flex-basis: 10%;
}

.header-book-now {
	flex-basis: 10%;
}

.header-img a {
	min-height: 24px;
	min-width: 250px;
}

.header-stuf-img img {
	z-index: 10;
	position: absolute;
	min-height: 24px;
	min-width: 230px;
}

.header-menu-btn {
	z-index: 10;
	position: absolute;
}
