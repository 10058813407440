.hero_container {
	height: 751px;
}

.left_container {
	width: 100vw;
	min-width: 100vw;
}

@media only screen and (min-width: 1024px) {
	.left_container {
		flex-basis: 50%;
		min-width: 512px;
	}

	.right_container {
		min-width: 512px;
		flex-basis: 50%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}

.text_heading {
	flex-basis: 40%;
}

.text_subheading {
	flex-basis: 15%;
}

.location_container {
	padding-left: 124px;
	padding-right: 124px;
}

.hero-address-form,
.hero-address-search {
	width: 100%;
}

.address-search-input {
	border-radius: 10px;
}
